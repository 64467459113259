export function getParams(url, strName) {
    var strHref = url || window.location.href
    var intPos = strHref.indexOf('?')
    var strRight = strHref.substr(intPos + 1)
    var arrTmp = strRight.split('&')
    let str = ''
    for (var i = 0; i < arrTmp.length; i++) {
        var arrTemp = arrTmp[i].split('=')
        if (arrTemp[0].toUpperCase() === strName.toUpperCase()) { str = arrTemp[1] }
    }
    if (str.includes('#/')) {
        return str.split('#')[0]
    } else {
        return str
    }
}