import Vue from 'vue'
import VueRouter from 'vue-router'
import { getParams } from '../utils/getParams';
import request from '../utils/interceptor'

Vue.use(VueRouter)


const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const url = window.location.href
  if(localStorage.getItem('userInfo')){
    next()
  }else if (window.location.href.indexOf('ticket') > 0) {
    const ticket = getParams(window.location.href, 'ticket');
    request('/sys/sso/verifyTicket/', {
      params: {
        ticket: ticket
      }
    }).then(res => {
      if (res.code === 200) {
        localStorage.setItem('userInfo',JSON.stringify(res.data))
        next()
      } else {
        window.location.href = `${process.env.VUE_APP_BASE_SSO}?srv=${url}&appKey=81fbbadc1d0040749d21eb3f6b863646`
      }
    })
  } else {
    window.location.href = `${process.env.VUE_APP_BASE_SSO}?srv=${url}&appKey=81fbbadc1d0040749d21eb3f6b863646`
  }
})

export default router
